import { Container, Link, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography
        margin={4}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {t('attribution.createdBy')}{' '}
        <Link href="https://github.com/jhaals/yopass">Johan Haals</Link>
      </Typography>
      <Typography
        margin={4}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {t('attribution.modifiedBy')}{' '}
        <Link href="https://www.o7conseils.com">
          <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '2px',
                paddingBottom: '5px',
                height: '20px',
              }}
              component="img"
              alt="O7 Conseils"
              src="o7-conseils-rect.svg"
          />

        </Link>
      </Typography>

    </Container>
  );
};
