import { createTheme } from '@mui/material/styles';

// https://mui.com/material-ui/customization/theming/


const o7primary = {
  main: '#003e9a',
  light: '#0167ff',
  dark: '#001f4e',
  contrastText: '#fff',
};

const o7secondary = '#f95503';



// const typography: TypographyOptions = {
//   h1: {
//     color: o7secondary,
//   },
// };

export const theme = createTheme({
  palette: {
    primary: o7primary,
  }
});

theme.typography.h4.color = o7secondary;