import { AppBar, Toolbar, Typography, Button, Box, Link, FormLabel, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


export const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isOnUploadPage = location.pathname.includes('upload');
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  const upload = base + '/#/upload';
  const english = base + '?lng=en';
  const french = base + '?lng=fr';

  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar>
        <Link href={home} color="inherit" underline="none">
          <Typography variant="h6" component="div">
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '5px',
                height: '40px',
              }}
              component="img"
              height="40"
              alt="O7 Secrets"
              src="o7secrets.svg"
            />
          </Typography>
        </Link>
        <Box display="flex" sx={{marginLeft: 'auto'}}>
          <Link href={french} color="inherit" underline="none">
            <FormLabel component="legend">FR</FormLabel>
          </Link>
          <FormLabel sx={{paddingLeft: '5px', paddingRight: '5px'}} component="legend">|</FormLabel>
          <Link href={english} color="inherit" underline="none">
            <FormLabel component="legend">EN</FormLabel>
          </Link>
        </Box>
        <Box
          sx={{
            // marginLeft: 'auto',
            paddingLeft: '20px'
          }}

        >


          <Button
            component={Link}
            href={isOnUploadPage ? home : upload}
            variant="contained"
            color="primary"
          >
            {isOnUploadPage ? t('header.buttonHome') : t('header.buttonUpload')}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
